import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
import { Link } from 'cccisd-react-router';
import { getHomeRoute } from '../../helpers';
import Style from './style.css';

var Fortress = window.cccisd.fortress;

const Welcome = () => {
    const homepageRoute = getHomeRoute();

    return (
        <div className={'row ' + Style.wrapper}>
            <div className="col-md-8">
                <h1>Welcome to Classroom WISE</h1>
            </div>
            <div className="col-md-4">
                {Fortress.auth() ? (
                    <div className={Style.welcomeBox}>
                        <p>Hello, {Fortress.user.username}</p>
                        {homepageRoute && (
                            <Link to={homepageRoute}>
                                <button type="button" className="btn btn-primary">
                                    Go To Home
                                </button>
                            </Link>
                        )}
                    </div>
                ) : (
                    <LoginForm showPasswordToggle />
                )}
            </div>
        </div>
    );
};

export default Welcome;
